import * as React from 'react'
import * as css from './Header.module.css'
import MenuToggle from './MenuToggle'
import Logo from './Logo'
import HeaderLinks from './HeaderLinks'
import LoginState from './LoginState'
import clsx from 'clsx'

export default function Header() {
  return (
    <header className={css.root}>
      <div className={clsx(css.inner, 'container-lg padding-x')}>
        <MenuToggle />
        <Logo />
        <HeaderLinks />
        <LoginState />
      </div>
    </header>
  )
}
